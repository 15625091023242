import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import * as firebase from "firebase";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { IGeneralSettingsOrder, IImprovements, IMachine, IMachines, IPaper, IPaperPrice, IParameter } from "../interfaces/setting";
import * as inputs from "../../assets/data/inputs/parameters.json";
import { IPaperItem, IThicknessData } from "../interfaces/paper";
import * as _ from "lodash";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class SettingService {
  general: IGeneralSettingsOrder = {
    generalSettings: {},
    businessDetails: {},
    settingPoints: {}
  };
  paper: any = {
    size: {},
    thickness: {},
    type: {}
  };
  machines: IMachines = {};
  improvements: IImprovements[];
  settingLoad$: any = new Subject();
  router = "machines";
  isEdit: any;
  inputs: any;
  size: boolean = false;
  pricePerPoints: number = 1;
  priceChange$: Subject<number> = new Subject<number>();
  currencyCode: any = "ILS"

  constructor(private afs: AngularFirestore, private http: HttpClient, private injector: Injector) {
    this.getGeneral();
    this.getPaper();
    this.getImprovements();
    this.getMachines();
    this.getInputs();
  }


  changePrice(price) {
    this.pricePerPoints = price
    this.priceChange$.next(price);
  }

  getPricePoint(): number {
    if (this.pricePerPoints && this.pricePerPoints != 1)
      return this.pricePerPoints
    if (this.general && this.general.settingPoints && this.general.settingPoints.price) {
      this.changePrice(this.general.settingPoints.price)
      return this.pricePerPoints
    }
    this.changePrice(1)
    return 1
  }

  saveGeneral() {
    console.log('saveGeneral', this.general);

    this.afs.collection("setting").doc("General").update(this.general);
  }

  async getGeneral() {
    if (Object.keys(this.general.generalSettings).length != 0 ||
      Object.keys(this.general.businessDetails).length != 0 ||
      Object.keys(this.general.settingPoints).length != 0) {
      return this.general;
    }
    this.afs
      .doc(`setting/General`)
      .valueChanges()
      .subscribe((general: IGeneralSettingsOrder) => {
        this.general = general;
        this.currencyCode = this.general.generalSettings.currency
        console.log('this.general', this.general);
        if (this.general.generalSettings.VATcalculation == "included" ||
          this.general.generalSettings.VATcalculation == "with no")
          environment.vat = 0
        else
          environment.vat = this.general.generalSettings.VAT
        this.settingLoad$.next();

        let currency;
        currency = this.getCurrency()
        currency = "\"" + currency + "\""
        document.documentElement.style.setProperty("--currency", currency);

        let measure = "\"" + this.general.generalSettings.measure + "\""
        document.documentElement.style.setProperty("--measure", measure);
      });
  }

  getCurrency() {
    let currency = "₪"
    switch (this.general.generalSettings.currency) {
      case "ILS": {
        currency = "₪"
        break
      }
      case "USD": {
        currency = "$"
        break
      }
      case "GBP": {
        currency = "£"
        break
      }
      case "EUR": {
        currency = "€"
        break
      }
    }
    return currency
  }

  async getPaper() {
    if (Object.keys(this.paper.size).length != 0 ||
      Object.keys(this.paper.thickness).length != 0 ||
      Object.keys(this.paper.type).length != 0) {
      return this.paper;
    }
    this.afs
      .doc(`setting/Paper`)
      .valueChanges()
      .subscribe((paper: IPaper[]) => {
        this.paper = paper;
        this.settingLoad$.next();
      });
  }

  async getImprovements() {
    if (this.improvements) {
      return this.improvements;
    }

    this.improvements = [];
    this.afs
      .doc(`setting/Improvements`)
      .valueChanges()
      .subscribe((improvements: IImprovements[]) => {
        this.improvements = improvements;
        this.settingLoad$.next();
      });
  }

  async getMachines() {
    this.afs
      .collection("machines")
      .snapshotChanges()
      .pipe(
        map((actions: any) => {
          return actions.map((a) => {
            const data: Object = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      )
      .subscribe((machines: IMachine[]) => {
        machines.map((m) => {
          this.machines[m.id] = m;
        });
        this.settingLoad$.next();
      });
  }

  getInputs() {
    if (this.inputs) {
      return this.inputs;
    }
    this.inputs = inputs["default"];
    this.settingLoad$.next();
  }

  addParameter(key: string, newValue: any) {
    if (!newValue) {
      return;
    }
    if (key === "type") {
      newValue = { name: newValue };
    }

    this.paper[key][this.random()] = newValue;
    this.savePaper();
  }

  editParameter(key: string, newValue: any, editKey: any) {
    if (!newValue) {
      return;
    }
    if (key === "type") {
      newValue = { name: newValue };
    }
    this.paper[key][editKey] = newValue;
    this.savePaper();
  }

  deleteSize(keyType, keyOfValue) {
    delete this.paper[keyType][keyOfValue.key];
    this.savePaper();
  }

  deleteParameter(keyType) {
    delete this.paper[keyType];
    this.savePaper();
  }

  savePaper() {
    this.afs.collection("setting").doc("Paper").update(this.paper);
  }

  savePaperPrice(typeID: string, paperPrice: any) {
    console.log("this.paper", paperPrice, this.paper["type"][typeID]);

    if (this.paper["type"][typeID]) {
      this.paper["type"][typeID] = { ...this.paper["type"][typeID], ...paperPrice };
      this.savePaper();
    }
  }

  deleteMachines(key) {
    this.afs.collection("machines/").doc(key).delete();
  }

  saveMachines() {
    const db = firebase.firestore();
    Object.entries(this.machines).map((machine) => {
      db.collection("machines").doc(machine[0]).update(machine[1]);
    });
  }

  getPaperData(paperID: string): IPaperItem {
    return _.get(this.paper, ["size", paperID], {});
  }

  getPaperTypeName(typeID: string): IPaperItem {
    return _.get(this.paper, ["type", typeID, "name"], "");
  }

  getThicknessData(thicknessID: string): number {
    return parseFloat(_.get(this.paper, ["thickness", thicknessID]));
  }

  getPaperAmount(width, height, thickness, pricePerTone) {
    const volume = thickness * height * width;
    const price = Math.round((pricePerTone / 1000000) * volume * 100) / 100;
    return price;
  }

  getPricePerTone(typePaperData: IPaperPrice, thicknessID: string, width: number, height: number): number {
    const paperGroupSize = this.getPaperGroupSize(typePaperData, width, height);
    return _.get(typePaperData, ["price", thicknessID, paperGroupSize], 0);
  }

  getPaperGroupSize(typePaperData: IPaperPrice, width: number, height: number) {
    const minSizeHeight = _.get(typePaperData, "minSizeHeight", 20);
    const minSizeWidth = _.get(typePaperData, "minSizeWidth", 20);
    return width <= minSizeWidth && height <= minSizeHeight ? "small" : "large";
  }

  getThickness(): IThicknessData {
    return _.get(this.paper, ["thickness"], {});
  }

  getThicknessName(thicknessID: string): IThicknessData {
    return _.get(this.paper, ["thickness", thicknessID], "");
  }

  getPaperTypes(): IThicknessData {
    return _.get(this.paper, ["type"], {});
  }

  getPaperSizeName(paperID: string): string {
    return _.get(this.paper, ["size", paperID, "name"]);
  }
  geMachineName(machineID: string): string {
    return _.get(this.machines, [machineID, "name"], '');
  }

  getPaperPrice(typeID: string) {
    return _.get(this.paper, ["type", typeID], {});
  }

  random() {
    return Math.floor(Math.random() * 1001).toString()
  }
}