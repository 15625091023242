import { IMagicMessageLabelDescription, IMagicMoreInfo, IMagicMoreInfoAlert, IMagicsInput, IMagicsList, IMagicsParams, IPaperOption } from "../interfaces/magics";
import { MainMagics } from "./mainMagics";
import * as _ from "lodash";
import { MagicsService } from "../services/magics.service";
import { SettingService } from "../services/setting.service";
import { ICartDesign, ICartTemplate } from "../interfaces/cart";
import { IProductInput } from "../interfaces/products";
import { TranslateProvider } from "../providers";
import { DataService } from "../data.service";

export class OffsetPrintingParametersMagic extends MainMagics {
  calculatorInputs: IMagicsInput[] = [
    {
      name: "machineID",
      type: "select",
      label: [{ text: this.translate.get("371_MACHINE") }],
      required: false,
      options: [],
      filter: [
        {
          key: "parameter",
          value: "84_offsetColorfulPrinting",
        },
        {
          key: "parameter",
          value: "86_offsetBlackAndWhitePrinting",
        }
      ],
    },
    {
      name: "amountOfColor",
      type: "select",
      label: [{ text: this.translate.get("700_AMOUNT_OF_COLORS") }],
      options: [
        { label: `1- ${this.translate.get("632_BLACK_WHITE")}`, value: "1" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
      required: false,
    },
    {
      name: "penton",
      type: "checkbox",
      label: [
        {
          text: this.translate.get("701_PENTON_COLOR_ADDITION"),
        },
      ],
      required: false,
    },
  ];

  constructor(public magicsService: MagicsService, private settingService: SettingService, private translate: TranslateProvider, public dataService: DataService) {
    super(magicsService, dataService);
  }
  async getMoreInfo(magicData: IMagicsParams, parameterPrice: number = 0, count: number = 0, design?: ICartDesign, template?: ICartTemplate, input?: IProductInput, values?: any): Promise<IMagicMoreInfo> {
    const alerts: IMagicMoreInfoAlert[] = [];
    const amountOfColor = _.get(values, "amountOfColor", 0);
    const machineID = _.get(values, "machineID", null);
    const machine = _.get(this.settingService.machines, [machineID])
    if (amountOfColor > 1) {
      let basic: IMagicMessageLabelDescription[] = [];
      let colorful = _.get(machine, ['parameters', '84_offsetColorfulPrinting'], null)
      if (!colorful) {
        alerts.push({
          type: "error",
          text: `${this.translate.get("371_MACHINE")} ${machine.name} ${this.translate.get("703_DOES_NOT_SUPPORT_COLORED_OFFSET_PRINTING_HAVE_A_MACHINE")}`,
        });
      }
      return {
        basic: basic,
        data: {values},
        alerts
      };
    }
  }
}
